<template>
  <h1>Tension &amp; Trauma Release Exercises</h1>
  <h2>Entspannungs- und Traumalösende Körperübungen</h2>
  <div class="img">
    <img
      src="../assets/Koerperuebungen.png"
      alt="Comicfiguren die Körperübungen durchführen"
    />
  </div>
  <ul>
    <li>Abbau von Stress</li>
    <li>Entspannung und Ausgeglichenheit</li>
    <li>Energie und Wohlbefinden</li>
  </ul>

  <h2>Tensioa &amp; Trauma Release Exercises</h2>
  <p>
    Die TRE-Übungen wurden von David Berceli entwickelt. Er ist klinischer
    Sozialarbeiter, Physiotherapeut und bioenergetischer Psychotherapeut und
    Experte im Fachgebiet Trauma. Er wird von unterschiedlichen Organisationen
    (Militär, Polizei, Feuerwehr) weltweit, in Krisen-und Kriegsgebiete gerufen,
    um Opfer und Ersthelfer schnell zu unterstützen.
  </p>
  <p>
    Nicht nur traumatische Erfahrungen, sondern auch hohe und dauerhafte
    Stressbelastungen im Lebensalltag, hinterlassen im Körper und auf
    emotionaler Ebene auf Grund von primären Überlebensreaktionen, häufig ein
    erhöhtes Spannungspotential. TRE sind sieben aufeinander abgestimmte
    Übungen, die auf körperlicher Ebene wirken und ohne
    therapeutische/psychologische Vertiefung arbeiten. Sie bewirken im Körper
    Vibrationen, Schwingungen und ein neurogenes Zittern (Muskelzittern),
    wodurch äußere und innere Spannungszustände sowie Blockaden gelöst werden
    können.
  </p>
  <p>
    Der Körper als unser größter Verbündeter, kann mit Hilfe von TRE einen
    neuen, belebenden Energiedurchfluss, aber auch tiefe Entspannung erfahren.
    Das kann sich wiederum positiv auf alle Ebenen des Menschen auswirken und zu
    einem allgemeinen, gesteigerten Wohlbefinden beitragen. Die Übungen sind
    leicht zu erlernen und für Jede und Jeden geeignet, für Kinder und
    Jugendliche, genauso wie für Erwachsene und ältere Menschen. Ich biete TRE
    in Gruppen, für Familien und in der Einzelarbeit zur ganzheitlichen
    Gesundheitsförderung an.
  </p>

  <h2>Hinweise zu TRE</h2>
  <p>
    TRE ist gegenwärtig keine wissenschaftlich anerkannte Methode, findet aber
    weltweit immer mehr Zuspruch in der präventiven Gesundheitsvorsorge. Zudem
    wird TRE von Fachleuten (Psychologen) in vertiefenden, therapeutischen
    Verfahren zur Behandlung von Traumata eingesetzt.
  </p>
</template>

<script>
export default {
  name: "Tension_Trauma"
};
</script>

<style></style>
